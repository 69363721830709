/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';

import { dehydrate, QueryClient } from 'react-query';

import {
  BackgroundBannerDefault,
  Sidebar,
  LoadingScreen
} from 'components/index';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { Navbar } from 'parts/landingpage';
import { MainContent } from 'parts/landingpage/index';
import { NextSeo } from 'next-seo';
import { useLoading } from 'utils/useLoading';
import { FrontendAPI } from 'services';
import { setToggle } from 'redux/toggleSlice';
import ResponseErrorServerSide from 'components/ResponseErrorServerSide';

export async function getServerSideProps(context) {
  const queryClient = new QueryClient();

  const lang = context?.locale === 'en' ? 'EN' : 'ID';

  // index pertama harus paling atas
  await queryClient.prefetchQuery('landing-page', () =>
    FrontendAPI.landingPage()
  );

  await queryClient.prefetchQuery('header-footer', () =>
    FrontendAPI.headerFooter()
  );

  await queryClient.prefetchQuery('office-pin-point', () =>
    FrontendAPI.officePinPoint()
  );
  // ===

  const allData = dehydrate(queryClient)?.queries?.map((item) => ({
    module: item.queryKey,
    state: item.state.data.data
  }));

  let result = {
    landingPage: null,
    headerFooter: null,
    coverageArea: null,

    // err
    isError: null
  };

  // onError server
  if (
    !dehydrate(queryClient)?.queries[0]?.state?.data?.success ||
    !dehydrate(queryClient)?.queries[1]?.state?.data?.success ||
    !dehydrate(queryClient)?.queries[2]?.state?.data?.success
  ) {
    result.isError = true;
  }

  // onSuccess
  if (dehydrate(queryClient)?.queries[0]?.state?.data?.success) {
    result.landingPage = allData[0];
  }

  if (dehydrate(queryClient)?.queries[1]?.state?.data?.success) {
    result.headerFooter = allData[1];
  }

  if (dehydrate(queryClient)?.queries[2]?.state?.data?.success) {
    result.coverageArea = allData[2];
  }

  return {
    props: {
      // getBySlug: dehydrate(queryClient)?.queries[0]?.state?.data,
      // landingPage: allData[0],
      // headerFooter: allData[1],
      // coverageArea: allData[2],

      ...result,
      lang
    }
  };
}

function Home({ landingPage, headerFooter, coverageArea, isError, lang }) {
  // seo
  const seo = landingPage?.state?.seo_data;
  // ===

  // navbar
  const navbar = headerFooter?.state?.header;
  // ===

  // banner
  const banner = landingPage?.state?.banner;
  // ===

  // coverageArea
  let CoverageArea = [];
  coverageArea?.state?.forEach((item) => {
    CoverageArea.push(item.jvector_code);
  });
  // ===

  // navigasi menu
  const navigasiMenu = landingPage?.state?.navigationMenu;
  // ===

  const router = useRouter();
  const loading = useLoading(false);
  const dispatch = useDispatch();
  // const language = useSelector((state) => state.toggle.language);
  const language = lang;
  const all_path = router.asPath.split('/');

  const handleChangeLanguage = () => {
    if (language === 'EN') {
      // router.push('/id');

      // using i18n
      dispatch(setToggle({ language: 'ID' }));
      router.push('/', '/', { locale: 'id' });
    } else {
      // router.push('/');

      dispatch(setToggle({ language: 'EN' }));
      router.push('/', '/', { locale: 'en' });
    }
  };

  useEffect(() => {
    // check first load
    if (language === 'ID') {
      dispatch(setToggle({ language: 'ID' }));
    }
    if (language === 'EN') {
      dispatch(setToggle({ language: 'EN' }));
    }
  }, []);

  function openNav() {
    document.getElementById('mySidenav').style.width = '320px';
  }

  function closeNav() {
    document.getElementById('mySidenav').style.width = '0';
  }

  if (loading) return <LoadingScreen />;

  return (
    <>
      {isError && <ResponseErrorServerSide />}

      <NextSeo
        title={
          language === 'ID' ? seo?.id_seo_page_title : seo?.en_seo_page_title
        }
        description={language === 'ID' ? seo?.id_meta_desc : seo?.en_meta_desc}
        // openGraph={{
        //   images: [

        //   ]
        // }}
        additionalMetaTags={[
          {
            property: 'keywords',
            content:
              language === 'ID' ? seo?.id_meta_keyword : seo?.en_meta_keyword
          }
        ]}
        additionalLinkTags={[
          {
            rel: 'canonical',
            href: `${process.env.URL_FRONTEND}`
          },
          {
            rel: 'icon',
            href: navbar?.web_icon
          }
        ]}
      />

      <div className="landingpage-page">
        <BackgroundBannerDefault
          data={banner}
          language={language}
          isLandingPage={true}
        />

        {/* NAVIGATION */}
        <div className="codrops-top">
          <Navbar
            data={navbar}
            changeLanguage={handleChangeLanguage}
            language={language}
          />

          <Sidebar
            closeNav={closeNav}
            openNav={openNav}
            data={navbar}
            changeLanguage={handleChangeLanguage}
            language={language}
          />
        </div>
        {/* NAVIGATION */}

        <MainContent
          language={language}
          data={navigasiMenu}
          CoverageArea={CoverageArea}
        />
      </div>
    </>
  );
}

export default Home;
