import Swal from 'sweetalert';

const ResponseErrorServerSide = (err) => {
  if (err.response) {
    Swal(err.response?.data?.message, '', 'error');
  } else if (err.request) {
    // The request was made but no response was received
    Swal('Error Occured. Please Try Again Later!', '', 'error');
  } else {
    // Something happened in setting up the request that triggered an Error
    Swal('Error Occured. Please Try Again Later!', '', 'error');
  }
};

export default ResponseErrorServerSide;
